import { Button, WEEKDAYS_LONG } from "modules/common";
import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as LocationPin } from "assets/icons/location-pin.svg";
import { ReactComponent as Redirect } from "assets/icons/redirect.svg";
import { differenceInMilliseconds, format } from "date-fns";
import { EducationItem } from "services";
import { groupBy } from "lodash";
import { orderBy } from "lodash";
import { EducationReminders } from "./EducationReminders";
import { useReminderHandler } from "../hooks";

interface Props {
  items: EducationItem[];
  onClick: (value: number) => void;
  filter: number;
}

export const EducationList: React.FC<Props> = ({ items, onClick, filter }) => {
  const [currentPickedEducation, setCurrentPickedEducation] =
    useState<EducationItem>();
  const [isReminderOpen, setIsReminderOpen] = useState(false);

  const { handleAddReminder, pickedReminders } = useReminderHandler(
    currentPickedEducation
  );

  // Store mapped refs
  const reminderRef = useRef<HTMLDivElement[]>([]);
  reminderRef.current = [];

  const filterTitle = ["", "Prijavljeni", "Odslušali", "Podsjetnici"][filter];

  function formatTime(data: string) {
    const date = new Date(data);

    return format(date, "HH:mm");
  }

  // Group data by date and create array
  const orderByStartDate = orderBy(items, (item) => item.startDate, "asc");
  const groupByStartDate = groupBy(
    orderByStartDate,
    (item) =>
      `"${new Date(item.startDate).getMonth()} + ${new Date(
        item.startDate
      ).getDate()}"`
  );
  const dataGroup = Object.keys(groupByStartDate).map((key) => ({
    startDate: key,
    values: groupByStartDate[key],
  }));

  function handleReminderDropdownClick(educationItem: EducationItem) {
    setIsReminderOpen((prev) => !prev);
    setCurrentPickedEducation(educationItem);
  }

  function handleAddToRef(element: HTMLDivElement) {
    if (!element || reminderRef.current.includes(element)) return;
    reminderRef.current.push(element);
  }

  // Handle reminder modal closing
  useEffect(() => {
    if (!reminderRef || !reminderRef.current) return;
    let checks: boolean[] = [];
    document.addEventListener("click", (event) => {
      checks = [];
      for (const reminder of reminderRef.current) {
        checks.push(reminder.contains(event.target as Node));
      }

      if (checks.includes(true)) return;
      setCurrentPickedEducation(undefined);
      setIsReminderOpen(false);
    });
  }, [reminderRef]);

  return (
    <>
      {dataGroup.map((itemGroup, index) => {
        return (
          <div key={index}>
            <h3 className="education__item-date">
              {WEEKDAYS_LONG[new Date(itemGroup.values[0].startDate).getDay()]}
              {", "}
              {format(new Date(itemGroup.values[0].startDate), "dd.MM.yyyy.")}
            </h3>

            <div className="separator" />

            {itemGroup.values.map((item, index) => {
              const showSignupButton =
                differenceInMilliseconds(
                  new Date(item.registrationDeadline),
                  new Date()
                ) > 0;

              return (
                <div key={index} className="f">
                  <p
                    className={`education__item-time ${
                      filter !== 2 && "education__item-time--active"
                    }`}
                  >{`${formatTime(item.startDate)} - ${formatTime(
                    item.endDate
                  )}`}</p>
                  <div className="education__list-wrapper">
                    {/* Header */}
                    <div className="education__item-header">
                      <h2 className="education__item-title">{item.title}</h2>
                      <div>
                        {filter !== 2 ? (
                          showSignupButton && (
                            <>
                              <div
                                className="education__list__reminder-wrapper"
                                ref={handleAddToRef}
                              >
                                <Button
                                  size="xxsmall"
                                  variant="primary-ghost"
                                  className={`btn--nowrap mr-8 ${
                                    pickedReminders.find(
                                      (reminder) =>
                                        reminder.education.uid === item.uid
                                    ) && "btn--reminder-active"
                                  }`}
                                  isDisabled={false}
                                  onClick={() =>
                                    handleReminderDropdownClick(item)
                                  }
                                >
                                  Podsjeti me
                                </Button>
                                <EducationReminders
                                  isReminderOpen={isReminderOpen}
                                  mappedEducation={item}
                                  pickedEducation={currentPickedEducation}
                                  handleAddReminder={handleAddReminder}
                                />
                              </div>
                              <Button
                                size="xxsmall"
                                variant="primary-ghost"
                                className="btn--nowrap"
                                isDisabled={!showSignupButton}
                                onClick={onClick.bind(null, item.semisId)}
                              >
                                Prijavi se
                              </Button>
                            </>
                          )
                        ) : (
                          <Button
                            size="xxsmall"
                            variant="primary-ghost"
                            className="btn--nowrap"
                            isDisabled={false}
                            to="https://www.savjetodavna.hr/potvrde/" // TODO: Add correct link
                          >
                            Preuzmite potvrdu <Redirect className="ml-8" />
                          </Button>
                        )}
                      </div>
                    </div>

                    {/* Subheader */}
                    <div className="f">
                      {item.county && (
                        <p className="educations__item-subheading educations__item-subheading--first">
                          {item.county}
                        </p>
                      )}
                      {item.expertArea && (
                        <p className="educations__item-subheading educations__item-subheading--second">
                          {item.expertArea}
                        </p>
                      )}
                      {item.measure && (
                        <p className="educations__item-subheading educations__item-subheading--third">
                          {item.measure}
                        </p>
                      )}
                    </div>

                    {/* Location */}
                    <p className="education__item-location">
                      <LocationPin className="mr-12" /> {item.address}
                    </p>

                    {/* Details */}
                    <div className="education__item-details">
                      {/* col1 */}
                      <div>
                        <h5 className="education__item-label">Kapacitet</h5>
                        <p className="mt-8 mb-24">{`${item.spotsAvailable}/${item.maxAttendees}`}</p>

                        <h5 className="education__item-label">Obaveza</h5>
                        <p
                          className={`education__item-obligation ${
                            item.isEducationMandatoryForFarmer &&
                            "education__item-obligation--active"
                          }`}
                        >
                          {item.isEducationMandatoryForFarmer ? "Da" : "Ne"}
                        </p>
                      </div>

                      {/* col2 */}
                      <div>
                        <h5 className="education__item-label">Predavači</h5>
                        {item.instructors.map((value, index) => {
                          return (
                            <p className="mt-8" key={index}>
                              {value.name}
                            </p>
                          );
                        })}
                      </div>

                      {/* col3 */}
                      <div>
                        <h5 className="education__item-label">Napomena</h5>
                        <p className="mt-8">{item.remark}</p>
                      </div>
                    </div>
                    <div className="separator" />

                    {item.educationStatuses?.length ? (
                      <div>
                        <h5 className="education__item-label">{filterTitle}</h5>
                        <p className="f mt-8">
                          {item.educationStatuses.map((value, index) => {
                            const showComma =
                              index + 1 < item.educationStatuses.length;

                            return (
                              <React.Fragment key={index}>
                                {value.farmerName} {value.farmerLastName}
                                {showComma && ", "}
                              </React.Fragment>
                            );
                          })}
                        </p>
                        <div className="separator" />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
