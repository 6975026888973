import {
  Input,
  Loading,
  NoResults,
  OptionType,
  Pagination,
  RangeDatePicker,
  RoleType,
} from "modules/common";
import {
  EducationFilters,
  EducationList,
  EducationSignupModal,
  EducationTabFilter,
  getEducations,
  getEducationsRequest,
} from "modules/education";
import React, { useState, useCallback, useEffect } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { DateUtils, DayModifiers } from "react-day-picker";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ValueType } from "react-select";
import { add, sub } from "date-fns";
import { sessionCache } from "modules/auth";
import { EducationItem } from "services";
import { getEducationsWithReminder } from "../store";
import { isMinistryAdviser } from "modules/auth/authorization/roleChecks";

const PAGE_NEIGHBOURS = 1;
const INPUT_DEBOUNCE_TIME = 500; //miliseconds
const isFarmer = (roleType: number) => RoleType.Farmer === roleType;

const DefaultPageSize = {
  value: "10",
  label: "10",
};

export const EducationSignup: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.profile);
  const user = sessionCache().getSession();
  const dispatch = useDispatch();
  const {
    educations,
    appliedEducations,
    listenedEducations,
    educationsWithReminders,
    isLoading,
  } = useSelector((state: RootState) => state.educations);
  const roleType = user?.roleType.value ?? profile?.roleType?.value;

  const Filters = isFarmer(roleType ?? RoleType.Farmer)
    ? ["Dostupno", "Prijavljeno", "Odslušano", "Podsjetnici"]
    : isMinistryAdviser(roleType ?? RoleType.MinistryAdviser)
    ? ["Dostupno", "Podsjetnici"]
    : ["Dostupne edukacije"];

  // Get county from profile and upper case it for counties filter
  const profileCounty = profile?.county.toUpperCase().startsWith("GRAD")
    ? profile?.county.toUpperCase()
    : profile?.county.toUpperCase() + " ŽUPANIJA";

  const [tabFilter, setTabFilter] = useState<number>(0);
  const [countiesFilter, setCountiesFilter] = useState<string[]>(
    profileCounty ? [profileCounty] : []
  );
  const [measuresFilter, setMeasuresFilter] = useState<string[]>([]);
  const [expertAreasFilter, setExpertAreasFilter] = useState<string[]>([]);
  const [isMandatoryFilter, setIsMandatoryFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [date, setDate] = useState<{
    from: Date;
    to: Date;
  }>({
    from: new Date(),
    to: add(new Date(), { months: 3 }),
  });
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<OptionType | null>(DefaultPageSize);
  const [semisId, setSemisId] = useState<number | undefined>();
  const [chosenEducations, setChosenEducations] = useState<{
    count: number;
    item: EducationItem[];
  }>();

  function onFilterChange(value: number) {
    setTabFilter(value);
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchTerm(value);
    setCurrentPage(1);
  }

  const handleDayClick = (
    day: Date,
    _modifiers: DayModifiers,
    _e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const range = DateUtils.addDayToRange(day, date);
    setDate({ from: range.from ?? new Date(), to: range.to ?? new Date() });
  };

  const updateRange = (from: Date, to: Date) => {
    setDate({ from, to });
  };

  const handlePageSizeChange = (value: ValueType<OptionType, false>) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const page = Number(parseInt(event.currentTarget.id));
    setCurrentPage(page);
  };

  const handleNexPageClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPageClick = () => {
    setCurrentPage((prev) => prev - 1);
  };

  function toggleModal(id?: number) {
    setSemisId(id);
    setShowModal(!showModal);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (
        currentPage: number,
        size: number,
        searchTerm: string | null,
        measures: string[],
        counties: string[],
        expertAreas: string[],
        dateFrom: Date,
        dateTo: Date,
        tabFilter: number,
        isMandatory: boolean
      ) => {
        dispatch(
          getEducations({
            pageNumber: currentPage,
            pageSize: size,
            title: searchTerm,
            measures,
            counties,
            expertAreas,
            dateFrom,
            dateTo,
            status: tabFilter === 0 ? null : tabFilter,
            isMandatory,
          })
        );

        dispatch(
          getEducationsWithReminder({ pageNumber: currentPage, pageSize: size })
        );
      },
      INPUT_DEBOUNCE_TIME
    ),
    []
  );

  useEffect(() => {
    if (tabFilter === 2) {
      setDate({ from: sub(new Date(), { years: 5 }), to: new Date() });
      return;
    }

    setDate({ from: new Date(), to: add(new Date(), { months: 3 }) });
  }, [tabFilter]);

  useEffect(() => {
    const size = parseInt(pageSize?.value ?? DefaultPageSize.value);

    if (date.from && date.to) {
      // api call is debounced, switch to loading earlier
      dispatch(getEducationsRequest());
      debouncedSearch(
        currentPage,
        size,
        searchTerm,
        measuresFilter,
        countiesFilter,
        expertAreasFilter,
        date.from,
        date.to,
        tabFilter,
        isMandatoryFilter
      );
    }
  }, [
    currentPage,
    debouncedSearch,
    dispatch,
    pageSize?.value,
    searchTerm,
    measuresFilter,
    countiesFilter,
    expertAreasFilter,
    date,
    tabFilter,
    isMandatoryFilter,
  ]);

  useEffect(() => {
    setChosenEducations(undefined);
  }, [tabFilter]);

  useEffect(() => {
    if (
      !educations &&
      !appliedEducations &&
      !listenedEducations &&
      !educationsWithReminders
    ) {
      setChosenEducations(undefined);
      return;
    }

    if (!tabFilter && educations) {
      setChosenEducations({
        count: educations.count,
        item: educations.item,
      });
      return;
    }

    if (Filters[tabFilter] === 'Prijavljeno' && appliedEducations) {
      setChosenEducations({
        count: appliedEducations.count,
        item: appliedEducations.item,
      });
      return;
    }

    if (Filters[tabFilter] === 'Odslušano' && listenedEducations) {
      setChosenEducations({
        count: listenedEducations.count,
        item: listenedEducations.item,
      });
      return;
    }

    if (Filters[tabFilter] === 'Podsjetnici' && educationsWithReminders) {
      setChosenEducations({
        count: educationsWithReminders.count,
        item: educationsWithReminders.items.map((e) => e.education),
      });
      return;
    }
  }, [
    educations,
    appliedEducations,
    listenedEducations,
    educationsWithReminders,
    tabFilter,
  ]);

  useEffect(() => {
    setPageSize(DefaultPageSize);
    setCurrentPage(1);
  }, [tabFilter]);

  return (
    <>
      <EducationTabFilter
        filters={Filters}
        setFilter={onFilterChange}
        activeFilter={tabFilter}
      />
      {Filters[tabFilter] !== "Podsjetnici" && (
        <div className="educations__filters mb-28">
          <EducationFilters
            countiesFilter={countiesFilter}
            setCountiesFilter={setCountiesFilter}
            measuresFilter={measuresFilter}
            setMeasuresFilter={setMeasuresFilter}
            expertAreasFilter={expertAreasFilter}
            setExpertAreasFilter={setExpertAreasFilter}
            isMandatoryFilter={isMandatoryFilter}
            setIsMandatoryFilter={setIsMandatoryFilter}
          />
          <div className="f">
            <Input
              name="naziv"
              className="search"
              id="naziv"
              type="text"
              size="small"
              placeholder="Naziv"
              isValid={true}
              onChange={handleSearchChange}
              errorMessage=""
              leftIcon={<SearchIcon className="search-icon" />}
              flexField
            />
            <div className="user-activity-date-filter f-1">
              <RangeDatePicker
                onClick={handleDayClick}
                selectedDays={date}
                modifiers={{ start: date.from, end: date.to }}
                updateRange={updateRange}
              />
            </div>
          </div>
        </div>
      )}

      {!isLoading ? (
        chosenEducations && chosenEducations.item.length ? (
          <>
            <EducationList
              items={chosenEducations.item}
              onClick={toggleModal}
              filter={tabFilter}
            />
            <Pagination
              className="mt-24"
              currentPage={currentPage}
              numberOfPages={Math.ceil(
                chosenEducations.count /
                  parseInt(pageSize?.value ?? DefaultPageSize.value)
              )}
              pageNeighbours={PAGE_NEIGHBOURS}
              pageSize={pageSize ?? DefaultPageSize}
              onPageSizeChange={handlePageSizeChange}
              handlepageClick={handlePageClick}
              next={handleNexPageClick}
              prev={handlePrevPageClick}
            />
          </>
        ) : (
          <NoResults className="mt-24" />
        )
      ) : (
        <Loading />
      )}

      <EducationSignupModal
        toggleModal={toggleModal}
        isVisible={showModal}
        semisId={semisId}
      />
    </>
  );
};
