export enum RoleType {
    None = 0,
    Citizen = 1,
    Farmer = 2,
    MinistryOfficial = 4,
    MinistryAdviser = 6,
    SuperAdmin = 9,
    PrivateAdviser = 13,
    CommunicatorAdviser = 14,
    EquipmentAdmin = 15,
    CommunicatorOfficial = 16,
}

export const userRoles: Record<string, { value: number; label: string }> = {
    Citizen: {
        value: RoleType.Citizen,
        label: "Građanin",
    },
    Farmer: {
        value: RoleType.Farmer,
        label: "Poljoprivrednik",
    },
    MinistryOfficial: {
        value: RoleType.MinistryOfficial,
        label: "Službenik ministarstva",
    },
    MinistryAdviser: {
        value: RoleType.MinistryAdviser,
        label: "Savjetnik ministarstva",
    },
    SuperAdmin: {
        value: RoleType.SuperAdmin,
        label: "Super administrator",
    },
    PrivateAdviser: {
        value: RoleType.PrivateAdviser,
        label: "Privatni savjetnik",
    },
    CommunicatorAdviser: {
        value: RoleType.CommunicatorAdviser,
        label: "Savjetnik komunikator",
    },
    EquipmentAdmin: {
        value: RoleType.EquipmentAdmin,
        label: "Administrator opreme",
    },
    CommunicatorOfficial: {
        value: RoleType.CommunicatorOfficial,
        label: "Službenik komunikator",
    },
};
