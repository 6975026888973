import { AbilityBuilder, Ability, AbilityClass } from "@casl/ability";
import {
    isCitizen,
    isFarmer,
    isMinistryAdviser,
    isMinistryOfficial,
    isSuperAdmin,
    isPrivateAdviser,
    isCommunicatorAdviser,
    isEquipmentAdmin,
    isCommunicatorOfficial,
} from "./roleChecks";
import { Subjects } from "./subjects";
import { Actions } from "./actions";
import {
    defineRulesForCitizen,
    defineRulesForFarmer,
    defineRulesForMinistryAdviser,
    defineRulesForMinistryOfficial,
    defineRulesForSuperAdmin,
    defineRulesForPrivateAdviser,
    defineRulesForCommunicatorAdviser,
    defineRulesForEquipmentAdmin,
    defineRulesForCommunicatorOfficial,
} from "./roleRules";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbilityModel = Ability as AbilityClass<AppAbility>;
export const ability = new AppAbilityModel([], {});

export default function defineRoleRules(role: number) {
    const abilityBuilder = new AbilityBuilder(AppAbilityModel);
    if (isFarmer(role)) {
        return defineRulesForFarmer(abilityBuilder);
    }

    if (isCitizen(role)) {
        return defineRulesForCitizen(abilityBuilder);
    }

    if (isMinistryAdviser(role)) {
        return defineRulesForMinistryAdviser(abilityBuilder);
    }

    if (isMinistryOfficial(role)) {
        return defineRulesForMinistryOfficial(abilityBuilder);
    }

    if (isSuperAdmin(role)) {
        return defineRulesForSuperAdmin(abilityBuilder);
    }

    if (isPrivateAdviser(role)) {
        return defineRulesForPrivateAdviser(abilityBuilder);
    }

    if (isCommunicatorAdviser(role)) {
        return defineRulesForCommunicatorAdviser(abilityBuilder);
    }

    if (isEquipmentAdmin(role)) {
        return defineRulesForEquipmentAdmin(abilityBuilder);
    }

    if (isCommunicatorOfficial(role)) {
        return defineRulesForCommunicatorOfficial(abilityBuilder);
    }

    return defineRulesForFarmer(abilityBuilder);
}

export function buildAbilityFor(role: number) {
    ability.update(defineRoleRules(role));
}

export function clearAbility() {
    ability.update([]);
}
