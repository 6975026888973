import { RoleType, useTitle } from "modules/common";
import { Overlay } from "modules/common/components/Overlay";
import { PanelMenuItem } from "modules/common/components/PanelMenu";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NewsRoutes } from "modules/common/constants/routes";
import { useRouteSearch } from "modules/common/hooks/useRouteSearch";
import { useSelector } from "react-redux";
import { RootState } from "store";

const getMenuItems = (userRole?: RoleType): PanelMenuItem[] => [
    {
        label: "Vijesti",
        link: NewsRoutes.News,
    },
    {
        label: "Savjeti",
        link: NewsRoutes.Advice,
    },
    {
        label:
            userRole === RoleType.Farmer || userRole === RoleType.Citizen
                ? "Moj Savjetnik"
                : "Savjetnici",
        link: NewsRoutes.Advisers,
        fullScreen: true,
    },
];

export const News: React.FC = () => {
    useTitle("Vijesti - eSavjetnik");
    const user = useSelector((state: RootState) => state.signIn?.user);
    const { search } = useLocation();
    const [menuItem, setMenuItem] = useState<PanelMenuItem>();
    const { debouncedSearch, searchElement } = useRouteSearch({
        routes: [NewsRoutes.News, NewsRoutes.Advice, NewsRoutes.Advisers],
        menuItem,
        inputProps: {
            placeholder:
                menuItem?.link === NewsRoutes.Advisers
                    ? "Pronađite savjetnika"
                    : "Pretraži po naslovu, sadržaju i kategoriji.",
        },
    });
    const menuItems = getMenuItems(user?.role?.value);

    return (
        <Overlay
            backButton
            search={searchElement}
            view={{
                title: "Vijesti",
                source: menuItem => `${menuItem?.link ?? ""}${search}`,
                parameters: { search: debouncedSearch },
                dynamicHeight: true,
            }}
            menuItems={menuItems}
            onMenuItemChanged={setMenuItem}
        />
    );
};
