import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForSuperAdmin = (
  abilityBuilder: AbilityBuilder<AppAbility>
) => {
  const { can, rules } = abilityBuilder;

  can("view", "CourseList");
  can("view", "FarmList");
  can("update", "UserRole");

  can("view", "SystemAdministrationModule");
  can("view", "UserActivityModule");
  can("view", "UserAdministrationModule");
  can("view", "InternalDocumentation");
  can("view", "PIO");
  can("view", "Webtop");
  can("view", "ReportProblem");
  can("view", "SuperAdminFilter");
  can("view", "InternalSystemsModule");
  can("view", "Records");
  can("view", "CommunicationModule");
  can("view", "WeatherAlarmToggle");
  can("view", "ModulesAdministrationModule");
  can("view", "AdditionalModules");

  return rules;
};
