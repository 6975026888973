import { ReactComponent as NewWindowIcon } from "assets/icons/new-window.svg";
import { ReactComponent as ExpandFullScreenIcon } from "assets/icons/expand-fullscreen.svg";
import { ReactComponent as ShrinkFullScreenIcon } from "assets/icons/shrink-fullscreen.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { OverlayState } from "../components/Overlay";
import { Tooltip } from "modules/common";

export interface OverlayTitleBarProps {
    aboveMenu?: boolean;
    text?: string;
    fullScreenButton?: boolean;
    newWindowButton?: boolean;
}

interface UseOverlayTitleBarProps {
    searchElement: JSX.Element | null;
    selectedLinkLabel: string | undefined;
    selectedLinkDescription: string | undefined;
    state: OverlayState;
    titleBarProps?: OverlayTitleBarProps;
}

export const useOverlayTitleBar = ({
    searchElement,
    selectedLinkLabel,
    selectedLinkDescription,
    state: {
        backButton,
        fullScreen,
        isBackButtonVisible,
        previouslySelectedMenuItem,
        selectedMenuItem,
        setFullScreen,
        setIsBackButtonVisible,
        changeSelectedMenuItem,
    },
    titleBarProps,
}: UseOverlayTitleBarProps) => {
    // (<) Back button
    const backButtonElement: JSX.Element | null =
        backButton && fullScreen && isBackButtonVisible ? (
            <div
                className="round-button circle"
                onClick={() => {
                    if (selectedMenuItem?.fullScreen)
                        changeSelectedMenuItem(previouslySelectedMenuItem);

                    setFullScreen(false);
                    setIsBackButtonVisible(false);
                }}
            >
                <ChevronLeftIcon />
            </div>
        ) : null;

    // title bar can be displayed in two areas - above the menu (<PanelMenu />) or to the right of it (top part of the right 2/3)
    let topPart: JSX.Element | null = fullScreen ? (
        // if we are in full screen, top part consists of the search and/or back button
        <div className="overlay-top">
            {searchElement}
            {backButtonElement}
        </div>
    ) : (
        // if we are not in full screen, default to using the back button
        backButtonElement
    );

    if (!titleBarProps)
        return {
            backButtonElement,
            topPart,
        };

    const { text, newWindowButton, fullScreenButton } = titleBarProps;
    const titleBarElement = (
        <div className="title-bar">
            <div className="title-bar-left">
                {backButtonElement}
                <span className="title-bar-description">{selectedLinkDescription}</span>
                <span className="title-bar-text">{text ?? selectedLinkLabel}</span>
            </div>

            <div className="title-bar-right">
                {newWindowButton && selectedMenuItem && (
                    <Tooltip
                        tooltipText="Otvori u novom prozoru"
                        tooltipPosition="top"
                        id="new-window-tooltip"
                    >
                        <div
                            className="title-bar-icon"
                            onClick={() => window.open(selectedMenuItem.link, "_blank")}
                        >
                            <NewWindowIcon />
                        </div>
                    </Tooltip>
                )}
                {fullScreenButton && (
                    <Tooltip
                        tooltipText={fullScreen ? "Smanji" : "Povećaj"}
                        tooltipPosition="top"
                        id="full-screen-tooltip"
                    >
                        <div className="title-bar-icon" onClick={() => setFullScreen(!fullScreen)}>
                            {fullScreen ? <ShrinkFullScreenIcon /> : <ExpandFullScreenIcon />}
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );

    // if the title bar will not be placed above the menu (<PanelMenu />), use it as the top part
    if (!titleBarProps.aboveMenu) topPart = titleBarElement;

    return {
        backButtonElement,
        titleBarElement,
        topPart,
    };
};
