import { formErrorMessages, isValidMobile } from "modules/auth";
import { Button, Input, ProfileInfo } from "modules/common";
import { updateMobileNumber } from "modules/dashboard";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

export const ProfileInfoForm: React.FC = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state: RootState) => state.profile);

    const [state, setState] = useState<{
        mobileNumber: string | undefined;
    }>({
        mobileNumber: undefined,
    });
    const [formErrors, setFormErrors] = useState<Record<string, string>>({});

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;
        setState(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const clearFormError = (field: string) => {
        const filtered = Object.keys(formErrors)
            .filter(key => key !== field)
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: formErrors[key],
                };
            }, {});
        setFormErrors({ ...filtered });
    };

    const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value, name } = event.target;

        if (!isValidMobile(value)) {
            setFormErrors(prev => ({
                ...prev,
                [name]: formErrorMessages.invalidPhone,
            }));
            return;
        }

        clearFormError(name);
    };

    function onSubmit() {
        if (state.mobileNumber !== profile?.mobileNumber || !formErrors["mobileNumber"]) {
            dispatch(updateMobileNumber(state.mobileNumber ?? ""));
        }
    }

    return (
        <ProfileInfo>
            <label className="profile-settings-label" htmlFor="phone">
                Kontakt broj
            </label>
            <div className="profile-settings-input">
                <Input
                    id="mobileNumber"
                    type="text"
                    size="large"
                    isValid={formErrors["mobileNumber"] ? false : true}
                    name="mobileNumber"
                    errorMessage=""
                    onChange={handleInputChange}
                    onBlur={handlePhoneBlur}
                    value={state.mobileNumber ?? profile?.mobileNumber}
                />
            </div>
            <div className="profile-settings-button ">
                <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    isDisabled={Boolean(Object.keys(formErrors).length)}
                    onClick={onSubmit}
                >
                    <span>Spremi izmjene</span>
                </Button>
            </div>
        </ProfileInfo>
    );
};
