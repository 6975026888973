import { Can } from "modules/auth";
import { Button } from "modules/common";
import React from "react";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";

export const EducationLinks: React.FC = () => {
  return (
    <>
      <Can I="view" a="FarmList">
        <a
          className="educations-category"
          href="https://semis.mps.hr/yiisemis/index.php?r=apprrrPgList/admin"
          rel="noreferrer"
          target="_blank"
        >
          <span className="educations-category__title">
            Analiza edukacija PG-ova
          </span>
          <Button size="xxsmall" variant="primary-ghost" isDisabled={false}>
            <LinkIcon className="btn--right-icon btn-icon--xsmall" />
            <span>Detalji</span>
          </Button>
        </a>
      </Can>

      <Can I="view" a="CourseList">
        <div className="separator" />
        <a
          className="educations-category"
          href="https://semis.mps.hr/yiisemis/index.php?r=karticaTecaja/courseCalendarView"
          rel="noreferrer"
          target="_blank"
        >
          <span className="educations-category__title">Kalendar edukacija</span>
          <Button size="xxsmall" variant="primary-ghost" isDisabled={false}>
            <LinkIcon className="btn--right-icon btn-icon--xsmall" />
            <span>Detalji</span>
          </Button>
        </a>
      </Can>
    </>
  );
};
