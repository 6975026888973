import React, { useEffect, useMemo, useState } from "react";
import { DateUtils, DayModifiers } from "react-day-picker";
import Masonry from "react-masonry-css";
import {
  HorizontalBarChart,
  mockModuleUsageData,
  RangeDatePicker,
  StatisticsContainer,
  UsersStats,
  useTitle,
} from "modules/common";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import {
  getAccountDashboardData,
  ModuleUsageStats,
  NotificationsStats,
  RegistrationStats,
  SubscriptionsStats,
  SystemAdministrationSkeleton,
} from "modules/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { CSVLink } from "react-csv";
import { sum, values } from "lodash";

export const SystemAdministration: React.FC = () => {
  useTitle('Administracija sustava - eSavjetnik');
  const dispatch = useDispatch();
  const { data, isFetching } = useSelector(
    (state: RootState) => state.dashboardData
  );
  const [date, setDate] = useState<{
    from: Date;
    to: Date;
  }>({
    from: new Date(),
    to: new Date(),
  });

  const handleDayClick = (
    day: Date,
    _modifiers: DayModifiers,
    _e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const range = DateUtils.addDayToRange(day, date);
    setDate({ from: range.from ?? new Date(), to: range.to ?? new Date() });
  };

  const updateRange = (from: Date, to: Date) => {
    setDate({ from, to });
  };

  const { from, to } = date;
  const modifiers = { start: from, end: to };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Naziv",
        accessor: "label",
        className: "name-header",
      },

      {
        Header: "Web",
        accessor: (data: any) => (
          <div className="module-table-values">{data.web}</div>
        ),
        className: "web-header",
        id: 2,
      },
      {
        Header: "Mobilni",
        accessor: (data: any) => (
          <div className="module-table-values">{data.mobile}</div>
        ),
        className: "mobile-header",
        id: 3,
      },
      {
        id: "graph",
        Cell: () => <HorizontalBarChart data={[124, 100]} />,
      },
    ],
    []
  );

  useEffect(() => {
    if (date.from && date.to) {
      dispatch(
        getAccountDashboardData({ dateFrom: date.from, dateTo: date.to })
      );
    }
  }, [dispatch, date.from, date.to]);

  /** Prepare data for export */
  // function formatedCSVData() {
  //   if (!data) {
  //     return [];
  //   }

  //   const formatedData = [
  //     {
  //       Datum: `${format(new Date(data.dateFrom), "dd.MM.yyyy.")} - ${format(
  //         new Date(data.dateTo),
  //         "dd.MM.yyyy."
  //       )}`,
  //       "Vrsta predbilježbe": {
  //         Savjeti: data.accountDashboardInfoView.totalInfoAdviceCount,
  //         Najave: data.accountDashboardInfoView.totalInfoAnnouncementCount,
  //         Tečajevi:
  //           data.accountDashboardInfoView.totalInfoCoursesNotificationCount,
  //         Demonstracije:
  //           data.accountDashboardInfoView
  //             .totalInfoDemonstrationNotificationCount,
  //         Poljoprivreda:
  //           data.accountDashboardInfoView.totalInfoFarmingAndOtherCount,
  //         Voće:
  //           data.accountDashboardInfoView.totalInfoFruitAndOliveGrowingCount,
  //         Vijesti: data.accountDashboardInfoView.totalInfoNewsCount,
  //         Vrijeme: data.accountDashboardInfoView.totalInfoWetherAlarmCount,
  //         Vino: data.accountDashboardInfoView.totalInfoWineGrowingCount,
  //       },
  //     },
  //   ];

  //   return JSON.stringify(formatedData);
  // }

  const totalRegistered =
    (data?.accountDashboardRegisteredView?.totalFarmerAccountCount ?? 0) +
    (data?.accountDashboardRegisteredView?.totalCitizenAccountCount ?? 0);

  function sumObjectValues(data: unknown) {
    return sum(values(data));
  }

  return (
    <div className="system-administration">
      <div className="system-administration-header">
        <div className="system-administration-header-filters">
          <span className="system-administration-header-text">Informacije o sustavu</span>
        </div>
        <CSVLink
          className="btn btn--xsmall btn--primary"
          data={JSON.stringify(data)}
          filename="eSavjetnik-podaci.csv"
        >
          <DownloadIcon className="btn--right-icon" />
          <span className="download-button-text">Preuzmi</span>
        </CSVLink>
        <div className="date-range-filter">
          <RangeDatePicker
            onClick={handleDayClick}
            selectedDays={date}
            modifiers={modifiers}
            updateRange={updateRange}
          />
        </div>
      </div>

      {isFetching ? (
        <SystemAdministrationSkeleton />
      ) : (
        <>
          <Masonry
            breakpointCols={{ default: 3, 1024: 2, 768: 1 }}
            className="masonry-grid mt-28"
            columnClassName="masonry-grid__column"
          >
            <StatisticsContainer title="Broj korisnika">
              <UsersStats
                numberOfRegistered={
                  data?.accountDashboardRegisteredView
                    .totalAccountRegisteredCount || 0
                }
                numberOfSignedIn={
                  data?.accountDashboardRegisteredView.totalAccountLoginCount ||
                  0
                }
              />
            </StatisticsContainer>
            <RegistrationStats
              numberOfFarmers={
                data?.accountDashboardRegisteredView?.totalFarmerAccountCount ??
                0
              }
              numberOfCitizens={
                data?.accountDashboardRegisteredView
                  ?.totalCitizenAccountCount ?? 0
              }
              total={totalRegistered}
            />
            <SubscriptionsStats
              total={sumObjectValues(data?.accountDashboardInfoView)}
              usersByAdvices={
                data?.accountDashboardInfoView?.totalInfoAdviceCount || 0
              }
              usersByCourses={
                data?.accountDashboardInfoView
                  ?.totalInfoCoursesNotificationCount || 0
              }
              usersByDemonstrations={
                data?.accountDashboardInfoView
                  ?.totalInfoDemonstrationNotificationCount || 0
              }
              usersByManifestations={
                data?.accountDashboardInfoView?.totalInfoAnnouncementCount || 0
              }
              usersByFarmingRecommendations={
                data?.accountDashboardInfoView?.totalInfoFarmingAndOtherCount ||
                0
              }
              usersByNews={
                data?.accountDashboardInfoView.totalInfoNewsCount || 0
              }
            />

            <NotificationsStats
              numberOfEmailUsers={
                data?.accountDashboardNotificationView
                  ?.totalNotificationEmailCount || 0
              }
              numberOfSmsUsers={
                data?.accountDashboardNotificationView
                  ?.totalNotificationSmsCount || 0
              }
              numberOfViberUsers={
                data?.accountDashboardNotificationView
                  ?.totalNotificationViberCount || 0
              }
              numberOfWhatsAppUsers={
                data?.accountDashboardNotificationView
                  ?.totalNotificationWhatsappCount || 0
              }
            />
          </Masonry>
          <ModuleUsageStats columns={tableColumns} data={mockModuleUsageData} />
        </>
      )}
    </div>
  );
};
