import { Checkbox, userRoles } from "modules/common";

export interface IRoleSelector {
    value: number[];
    setValues?: (values: number[]) => void;
    disabled?: boolean;
    single?: boolean;
    size?: "small" | "base";
    columns?: 1 | 2 | 3 | 4;
    errorMessage?: string;
}

export const RoleSelector = ({
    value,
    setValues,
    disabled = false,
    single = false,
    columns = 1,
    errorMessage = "",
}: IRoleSelector) => {
    const columnClass = `columns-${columns}`;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: roleValue } = event.currentTarget;
        if (!setValues) return;
        if (single) {
            setValues([parseInt(roleValue)]);
            return;
        }
        if (value.includes(parseInt(roleValue))) {
            setValues(value.filter(role => role !== parseInt(roleValue)));
            return;
        }
        setValues([...value, parseInt(roleValue)]);
    };

    return (
        <div className={`new-roles-selector-container ${columnClass}`}>
            {Object.keys(userRoles).map(role => {
                const roleValue = userRoles[role].value;
                const roleText = userRoles[role].label;
                return (
                    <Checkbox
                        key={roleValue}
                        isDisabled={disabled || setValues === undefined}
                        type={single ? "radio" : "checkbox"}
                        value={roleValue.toString()}
                        checked={value.includes(roleValue)}
                        onChange={onChange}
                        htmlFor={`role-${roleValue}`}
                        text={roleText}
                        isReversed={true}
                    />
                );
            })}
            <p className="error-message">{errorMessage}</p>
        </div>
    );
};

export default RoleSelector;
