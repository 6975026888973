import { buildAbilityFor, logOff, sessionCache } from "modules/auth";
import { toast } from "react-toastify";
import { mapUserAccounts } from "modules/dashboard";
import { mapUserAccount, mapUserProfile } from "modules/dashboard/utils";
import {
    getUserAccount,
    getUserAccounts,
    getUserAccountsByUids,
    UserAccountsRequest,
    activateUser,
    updateRoles,
    getMyAccount,
    updateMyAccountSettings,
    updateMyAccountMobileNumber,
    getUserAccountDashboardData,
    deleteMyAccount,
    getAllCounties,
    getSettlements,
    getCityMunicipalities,
    updateMyAccountMetadata,
    UpdateMyAccountMetadataRequest,
    getFilterPreferences,
    updateFilterPreferences,
} from "services";
import { AppThunk } from "store";
import {
    getUserAccountRequest,
    getUserAccountSuccess,
    getUserAccountError,
    activateUserRequest,
    activateUserError,
    updateRolesRequest,
    getDashboardDataRequest,
} from ".";
import { getDashboardDataError, getDashboardDataSuccess } from "./accountDashboardDataSlice";
import { getAccountListError, getAccountListSuccess } from "./accountListSlice";
import {
    deactivateAccountError,
    deactivateAccountRequest,
    deactivateAccountSuccess,
} from "./deactivateAccountSlice";
import { activateUserSuccess } from "./activateUserSlice";
import {
    getProfileError,
    getProfileRequest,
    getProfileSuccess,
    updateProfileError,
    updateProfileRequest,
    updateProfileSuccess,
} from "./profileSlice";
import {
    getCityMunicipalitiesError,
    getCityMunicipalitiesRequest,
    getCityMunicipalitiesSuccess,
    getCountiesError,
    getCountiesRequest,
    getCountiesSuccess,
    getSettlementsError,
    getSettlementsRequest,
    getSettlementsSuccess,
} from "./addressSlice";
import { updateRolesError, updateRolesSuccess } from "./updateUserRoleSlice";

export const getAccountList =
    (data: UserAccountsRequest): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();

            if (state.signIn.user) {
                const { accessToken } = state.signIn.user;
                const response = await getUserAccounts(data, accessToken);

                dispatch(
                    getAccountListSuccess({
                        userAccounts: mapUserAccounts(response.data.userAccountList),
                        userAccountCount: response.data.userAccountCount,
                    })
                );
            }
        } catch (error: any) {
            const [first] = error.response.data.statuses;
            dispatch(getAccountListError({ errorMessage: first.message }));
        }
    };

export const getUserAccountData =
    (uid: string, roleType: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();

            if (state.signIn.user) {
                dispatch(getUserAccountRequest());
                const { accessToken } = state.signIn.user;
                const response = await getUserAccount(uid, roleType, accessToken);

                dispatch(getUserAccountSuccess({ user: mapUserAccount(response.data.account) }));
            }
        } catch (error: any) {
            const [first] = error.response.data.statuses;
            dispatch(getUserAccountError({ errorMessage: first.message }));
        }
    };

export const getCounties = (): AppThunk => async (dispatch, getState) => {
    try {
        const { signIn, address } = getState();

        if (signIn.user && !address.counties.length) {
            dispatch(getCountiesRequest());
            const { accessToken } = signIn.user;
            const response = await getAllCounties(accessToken);

            dispatch(getCountiesSuccess({ counties: response.data.counties }));
        }
    } catch (error: any) {
        dispatch(getCountiesError({ errorMessage: error }));
    }
};

export const getSettlementsByCountyId =
    (countyId: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { signIn, address } = getState();

            if (signIn.user && address.counties.length) {
                dispatch(getSettlementsRequest());
                const { accessToken } = signIn.user;
                const response = await getSettlements(accessToken, countyId);

                dispatch(getSettlementsSuccess({ settlements: response.data.settlements }));
            }
        } catch (error: any) {
            dispatch(getSettlementsError({ errorMessage: error }));
        }
    };

export const getCityMunicipalitiesByCountyIds =
    (countyIds: number[]): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { signIn, address } = getState();

            if (signIn.user && address.counties.length) {
                dispatch(getCityMunicipalitiesRequest());

                const { accessToken } = signIn.user;

                const response = await getCityMunicipalities(accessToken, countyIds.toString());

                console.log(response);

                dispatch(
                    getCityMunicipalitiesSuccess({
                        municipalities: response.data.municipalities,
                    })
                );
            }
        } catch (error: any) {
            console.log(error);
            dispatch(getCityMunicipalitiesError({ errorMessage: error }));
        }
    };

export const deactivateUserByUid =
    (uid: string, roleType: number, isDeactivate: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(activateUserRequest());
            const state = getState();

            if (state.signIn.user) {
                dispatch(activateUserRequest());
                const { accessToken } = state.signIn.user;
                await activateUser(uid, roleType, isDeactivate, accessToken);
                dispatch(activateUserSuccess());
            }
        } catch (error: any) {
            const [first] = error.response.data.statuses;
            dispatch(activateUserError({ errorMessage: first.message }));
        }
    };

export const updateUserRoles =
    (uid: string, roleType: number): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            if (state.signIn.user) {
                dispatch(updateRolesRequest());
                const { accessToken } = state.signIn.user;
                await updateRoles(uid, roleType, accessToken);

                dispatch(updateRolesSuccess());

                dispatch(getUserAccountData(uid, roleType));
            }
        } catch (error: any) {
            const [first] = error.response.data.statuses;
            dispatch(updateRolesError({ errorMessage: first.message }));
        }
    };

export const getProfile = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const session = sessionCache().getSession();
        const accessToken = state.signIn.user?.accessToken ?? session?.accessToken;

        if (accessToken) {
            dispatch(getProfileRequest());

            const result = await getMyAccount(accessToken);
            const profile = mapUserProfile(result.data.account);

            dispatch(getProfileSuccess({ profile: profile }));
            buildAbilityFor(profile.roleType?.value ?? 0);
            if (session) {
                sessionCache().updateSession({
                    ...session,
                    roleType: profile.roleType ?? { value: 0, label: "" },
                });
            }
        }
    } catch (error: any) {
        const [first] = error.response.data.statuses;
        dispatch(getProfileError({ errorMessage: first }));
    }
};

export const updateProfile =
    (notificationType: number[], infoType: number[]): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const session = sessionCache().getSession();
            const accessToken = state.signIn.user?.accessToken ?? session?.accessToken;

            if (accessToken) {
                await updateMyAccountSettings({ notificationType, infoType }, accessToken);
                dispatch(updateProfileSuccess());
            }
        } catch (error) {
            dispatch(updateProfileError());
        }
    };

/** Update metadata type for correct payload */
export const updateAccountMetadata =
    (metadata: UpdateMyAccountMetadataRequest): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const session = sessionCache().getSession();
            const accessToken = state.signIn.user?.accessToken ?? session?.accessToken;

            if (accessToken) {
                dispatch(updateProfileRequest());

                await updateMyAccountMetadata(metadata, accessToken);

                dispatch(updateProfileSuccess());

                // find solution for refetching profile for every profile update
                dispatch(getProfile());
            }
        } catch (error) {
            dispatch(updateProfileError());
        }
    };

export const updateMobileNumber =
    (mobileNumber: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const session = sessionCache().getSession();
            const accessToken = state.signIn.user?.accessToken ?? session?.accessToken;

            if (accessToken) {
                await updateMyAccountMobileNumber(mobileNumber, accessToken);
                dispatch(updateProfileSuccess());
                toast.success("Promjene uspješno spremljene");
            }
        } catch (error) {
            dispatch(updateProfileError());
            toast.error("Problem prilikom spremanja podataka, pokušajte ponovno...");
        }
    };

export const getAccountDashboardData =
    (data: { dateFrom: Date | null; dateTo: Date | null }): AppThunk =>
    async (dispatch, getState) => {
        try {
            const state = getState();
            const session = sessionCache().getSession();
            const accessToken = state.signIn.user?.accessToken ?? session?.accessToken;

            if (accessToken) {
                dispatch(getDashboardDataRequest());

                const response = await getUserAccountDashboardData(accessToken, data);

                dispatch(getDashboardDataSuccess(response.data));
            }
        } catch (error: any) {
            const [first] = error.response.data.statuses;
            dispatch(getDashboardDataError({ errorMessage: first }));
        }
    };

export const deactivateAccount = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const session = sessionCache().getSession();
        const accessToken = state.signIn.user?.accessToken ?? session?.accessToken;

        if (accessToken) {
            dispatch(deactivateAccountRequest());

            await deleteMyAccount(accessToken);

            dispatch(deactivateAccountSuccess());

            dispatch(logOff());
        }
    } catch (error: any) {
        const [first] = error.response.data.statuses;
        dispatch(deactivateAccountError({ errorMessage: first }));
    }
};
