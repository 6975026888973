import { AbilityBuilder } from "@casl/ability";
import { AppAbility } from "modules/auth";

export const defineRulesForEquipmentAdmin   = (abilityBuilder: AbilityBuilder<AppAbility>) => {
    const { can, rules, cannot } = abilityBuilder;

    cannot("update", "UserRole");

    can("view", "PresentationsModule");
    can("view", "EducationModule");
    can("view", "EducationLinks");
    can("view", "CourseList");
    can("view", "CourseRegistration");
    can("view", "FarmList");
    can("view", "AgroclimateStations");
    can("view", "DownloadPresentation");
    can("view", "SEMIS");
    can("view", "InternalDocumentation");
    can("view", "PIO");
    can("view", "Webtop");
    can("view", "ReportProblem");
    can("view", "InternalSystemsModule");
    cannot("view", "Records");
    can("view", "WeatherAlarmToggle");
    can("view", "AdditionalModules");

    return rules;
};
