import React from "react";
type TitleBarSectionProps = {
    children?: React.ReactNode;
};

const TitleBarLeft: React.FC<TitleBarSectionProps> = ({ children }) => (
    <div className="title-bar-left">{children}</div>
);

const TitleBarRight: React.FC<TitleBarSectionProps> = ({ children }) => (
    <div className="title-bar-right">{children}</div>
);

const TitleBar: React.FC<TitleBarSectionProps> & {
    Left: typeof TitleBarLeft;
    Right: typeof TitleBarRight;
} = ({ children }) => {
    const childrenArray = React.Children.toArray(children);
    const hasOnlyRightElement =
        childrenArray.length === 1 &&
        React.isValidElement(childrenArray[0]) &&
        childrenArray[0].type === TitleBarRight;
    return (
        <div className={`title-bar ${hasOnlyRightElement ? "title-bar-right-only" : ""}`}>
            {children}
        </div>
    );
};

TitleBar.Left = TitleBarLeft;
TitleBar.Right = TitleBarRight;

export default TitleBar;
