import * as React from "react";
import { infoTypes, ToggleSwitch } from "modules/common";
import { Can } from "modules/auth";
import TitleBar from "modules/common/components/TitleBar/TitleBar";

interface Props {
    values?: number[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Subscriptions: React.FC<Props> = ({ values, onChange }) => {
    if (!values) {
        return null;
    }
    return (
        <div>
            <TitleBar>
                <TitleBar.Left>
                    <span className="title-bar-text profile-title">Predbilježbe</span>
                </TitleBar.Left>
            </TitleBar>
            <span className="subscriptions__description">
                Primajte pravovremene obavijesti iz određenih kategorija ovisno o vašim interesima.
            </span>
            <div className="subscriptions">
                <label htmlFor={infoTypes.News.label} className="subscription-row__label">
                    Vijesti
                </label>
                <ToggleSwitch
                    htmlFor={infoTypes.News.label}
                    value={infoTypes.News.value.toString()}
                    onChange={onChange}
                    checked={values.includes(infoTypes.News.value)}
                    className="subscription-row__toggle"
                />

                <label htmlFor={infoTypes.Advice.label} className="subscription-row__label">
                    Savjeti
                </label>
                <ToggleSwitch
                    htmlFor={infoTypes.Advice.label}
                    value={infoTypes.Advice.value.toString()}
                    onChange={onChange}
                    checked={values.includes(infoTypes.Advice.value)}
                    className="subscription-row__toggle"
                />

                <Can I="view" a="WeatherAlarmToggle">
                    <label
                        htmlFor={infoTypes.WetherAlarm.label}
                        className="subscription-row__label"
                    >
                        Vremenski alarmi
                    </label>
                    <ToggleSwitch
                        htmlFor={infoTypes.Announcement.label}
                        value={infoTypes.Announcement.value.toString()}
                        onChange={onChange}
                        checked={values.includes(infoTypes.Announcement.value)}
                        className="subscription-row__toggle"
                    />
                </Can>
            </div>
            <div className="section-separator" />
            <span className="subscription-category">Edukacije</span>
            <div className="subscriptions">
                <label
                    htmlFor={infoTypes.CoursesNotification.label}
                    className="subscription-row__label"
                >
                    Edukacije
                </label>
                <ToggleSwitch
                    htmlFor={infoTypes.CoursesNotification.label}
                    value={infoTypes.CoursesNotification.value.toString()}
                    onChange={onChange}
                    checked={values.includes(infoTypes.CoursesNotification.value)}
                    className="subscription-row__toggle"
                />
            </div>
            <div className="section-separator" />
            <span className="subscription-category">Preporuke za zaštitu bilja</span>
            <div className="subscriptions">
                <label
                    htmlFor={infoTypes.RecommendationWineGrowing.label}
                    className="subscription-row__label"
                >
                    Vinogradarstvo
                </label>
                <ToggleSwitch
                    htmlFor={infoTypes.RecommendationWineGrowing.label}
                    value={infoTypes.RecommendationWineGrowing.value.toString()}
                    onChange={onChange}
                    checked={values.includes(infoTypes.RecommendationWineGrowing.value)}
                    className="subscription-row__toggle"
                />
                <label
                    htmlFor={infoTypes.RecommendationFruitAndOliveGrowing.label}
                    className="subscription-row__label"
                >
                    Voćarstvo i maslinarstvo
                </label>
                <ToggleSwitch
                    htmlFor={infoTypes.RecommendationFruitAndOliveGrowing.label}
                    value={infoTypes.RecommendationFruitAndOliveGrowing.value.toString()}
                    onChange={onChange}
                    checked={values.includes(infoTypes.RecommendationFruitAndOliveGrowing.value)}
                    className="subscription-row__toggle"
                />
                <label
                    htmlFor={infoTypes.RecommendationFarmingAndOther.label}
                    className="subscription-row__label"
                >
                    Ratarstvo i ostalo
                </label>
                <ToggleSwitch
                    htmlFor={infoTypes.RecommendationFarmingAndOther.label}
                    value={infoTypes.RecommendationFarmingAndOther.value.toString()}
                    onChange={onChange}
                    checked={values.includes(infoTypes.RecommendationFarmingAndOther.value)}
                    className="subscription-row__toggle"
                />
            </div>
        </div>
    );
};
