import * as React from "react";
import { notificationTypes, ToggleSwitch } from "modules/common";
// import { ReactComponent as SmsIcon } from "assets/icons/sms.svg";
import TitleBar from "modules/common/components/TitleBar/TitleBar";

interface Props {
    values?: number[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NotificationSettings: React.FC<Props> = ({ values, onChange }) => {
    if (!values) {
        return null;
    }
    return (
        <div className="notifications-container">
            <TitleBar>
                <TitleBar.Left>
                    <span className="title-bar-text profile-title">Način obavještavanja</span>
                </TitleBar.Left>
                <TitleBar.Right>
                    <span className="title-bar-description">
                        Ukoliko isključite sve opcije nećete moći pravovremeno primati obavijesti o
                        novostima i vašim obavezama.
                    </span>
                </TitleBar.Right>
            </TitleBar>
            <span className="notifications__description">
                Budite u toku sa svim novostima i obavezama.
            </span>
            <div className="notifications">
                <div>
                    <label
                        htmlFor={notificationTypes.Email.label}
                        className="notification-row__label"
                    >
                        {notificationTypes.Email.label} (primarno)
                    </label>
                </div>
                <ToggleSwitch
                    htmlFor={notificationTypes.Email.label}
                    value={notificationTypes.Email.value.toString()}
                    onChange={onChange}
                    checked={values.includes(notificationTypes.Email.value)}
                    className="notification-row__toggle"
                />
                {/* <div>
          <SmsIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.SMS.label}
            className="notification-row__label"
          >
            {notificationTypes.SMS.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.SMS.label}
          value={notificationTypes.SMS.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.SMS.value)}
          className="notification-row__toggle"
        /> */}

                {/*<div>
          <MessengerIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.WhatsApp.label}
            className="notification-row__label"
          >
            {notificationTypes.WhatsApp.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.WhatsApp.label}
          value={notificationTypes.WhatsApp.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.WhatsApp.value)}
          className="notification-row__toggle"
        /> */}

                {/* <div>
          <MessengerIcon className="notification-row__icon" />
          <label
            htmlFor={notificationTypes.Viber.label}
            className="notification-row__label"
          >
            {notificationTypes.Viber.label}
          </label>
        </div>
        <ToggleSwitch
          htmlFor={notificationTypes.Viber.label}
          value={notificationTypes.Viber.value.toString()}
          onChange={onChange}
          checked={values.includes(notificationTypes.Viber.value)}
          className="notification-row__toggle"
        /> */}

                <div>
                    <label
                        htmlFor={notificationTypes.Application.label}
                        className="notification-row__label"
                    >
                        {notificationTypes.Application.label}
                    </label>
                </div>
                <ToggleSwitch
                    htmlFor={notificationTypes.Application.label}
                    value={notificationTypes.Application.value.toString()}
                    onChange={onChange}
                    checked={values.includes(notificationTypes.Application.value)}
                    className="notification-row__toggle"
                />
            </div>
        </div>
    );
};
