import { userRoles } from "modules/common";
import React from "react";
import { Filter, RadioInput } from "../";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isActive: boolean;
  showDropdown: boolean;
  value: number;
  title?: string;
}

export const RoleFilterSingle: React.FC<Props> = ({
  onChange,
  onClick,
  onClear,
  isActive,
  showDropdown,
  value,
  title,
}) => {
  return (
    <Filter
      isActive={isActive}
      showDropdown={showDropdown}
      onClear={onClear}
      onClick={onClick}
      text={title || "Uloga"}
    >
      {/*   <Can I="view" a="CitizenFilter">
        </Can> */}
      <div>
        <RadioInput
          value={userRoles.Citizen.value.toString()}
          checked={userRoles.Citizen.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Citizen.label}
          text={
            <div className="filter__item__text">{userRoles.Citizen.label}</div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.Farmer.value.toString()}
          checked={userRoles.Farmer.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.Farmer.label}
          text={
            <div className="filter__item__text">{userRoles.Farmer.label}</div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.MinistryOfficial.value.toString()}
          checked={userRoles.MinistryOfficial.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MinistryOfficial.label}
          text={
            <div className="filter__item__text">
              {userRoles.MinistryOfficial.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.MinistryAdviser.value.toString()}
          checked={userRoles.MinistryAdviser.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.MinistryAdviser.label}
          text={
            <div className="filter__item__text">
              {userRoles.MinistryAdviser.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.SuperAdmin.value.toString()}
          checked={userRoles.SuperAdmin.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.SuperAdmin.label}
          text={
            <div className="filter__item__text">
              {userRoles.SuperAdmin.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.PrivateAdviser.value.toString()}
          checked={userRoles.PrivateAdviser.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.PrivateAdviser.label}
          text={
            <div className="filter__item__text">
              {userRoles.PrivateAdviser.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.CommunicatorAdviser.value.toString()}
          checked={userRoles.CommunicatorAdviser.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.CommunicatorAdviser.label}
          text={
            <div className="filter__item__text">
              {userRoles.CommunicatorAdviser.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.EquipmentAdmin.value.toString()}
          checked={userRoles.EquipmentAdmin.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.EquipmentAdmin.label}
          text={
            <div className="filter__item__text">
              {userRoles.EquipmentAdmin.label}
            </div>
          }
        />
      </div>
      <div>
        <RadioInput
          value={userRoles.CommunicatorOfficial.value.toString()}
          checked={userRoles.CommunicatorOfficial.value === value}
          onChange={onChange}
          size="small"
          htmlFor={userRoles.CommunicatorOfficial.label}
          text={
            <div className="filter__item__text">
              {userRoles.CommunicatorOfficial.label}
            </div>
          }
        />
      </div>
    </Filter>
  );
};
