import { RoleType } from "modules/common";

export const isCitizen = (role: number) => role === RoleType.Citizen;
export const isFarmer = (role: number) => role === RoleType.Farmer;
export const isMinistryOfficial = (role: number) => role === RoleType.MinistryOfficial;
export const isMinistryAdviser = (role: number) => role === RoleType.MinistryAdviser;
export const isSuperAdmin = (role: number) => role === RoleType.SuperAdmin;
export const isPrivateAdviser = (role: number) => role === RoleType.PrivateAdviser;
export const isCommunicatorAdviser = (role: number) => role === RoleType.CommunicatorAdviser;
export const isEquipmentAdmin = (role: number) => role === RoleType.EquipmentAdmin;
export const isCommunicatorOfficial = (role: number) => role === RoleType.CommunicatorOfficial;
