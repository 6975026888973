//TODO: Commented are not part of release 1.5
export enum AppRoutes {
    Home = "/",
    SignIn = "/prijava",
    SignInAd = "/prijava-active-directory",
    SignUp = "/registracija",
    ForgotPassword = "/zaboravljena-lozinka",
    ChangePassword = "/nova-lozinka",
    ActivateAccount = "/aktivacija-racuna",
    Education = "/unauthorized", //edukacija
    News = "/vijesti",
    AgroclimateStations = "/unauthorized", //agroklimatske-stanice
    Agrometeorology = "/agrometeorologija",
    Records = "/unauthorized", //evidencije
    PlantProtection = "/zastita-bilja",
    UserActivity = "/unauthorized", //aktivnost-korisnika
    UserAdministration = "/administracija-korisnika",
    Profile = "/profil",
    SystemAdministration = "/unauthorized", //administracija-sustava
    NotAuthorized = "/unauthorized",
    Presentations = "/", //prezentacije
    Communication = "/unauthorized", //komunikacija-s-korisnicima
    ModulesAdministration = "/administracija-modula",
    ModuleVersionsAdministration = "/administracija-verzija-modula",
    ExternalModules = "/vanjski-moduli",
}
